<template>
  <div>
    <img style="display:block;width:100%" src="../../assets/51.png" alt="">
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
    };
  },
  computed: {},
  mounted() {},
  methods: {}
}

</script>
<style lang='scss' scoped>
</style>